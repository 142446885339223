@charset "UTF-8";
.top-mv {
  height: 600px;
  padding-top: 80px;
  margin-bottom: 60px;
}

.top-mv--ttl {
  font-size: 60px;
  line-height: 1.3;
}

.top-mv--ttl strong {
  color: #ff8a16;
}

.top-mv--ttl_sub {
  font-size: 24px;
  font-weight: 700;
  color: #5e5e5e;
  line-height: 1.2;
  padding-top: 30px;
  padding-bottom: 20px;
  margin-top: 20px;
  border-top: 4px dotted #f4b800;
  display: inline-block;
}

.top-mv--ttl_sub_sp {
  display: none;
}

.top-mv .m-btn {
  width: 420px;
}

.top-mv .inner {
  position: relative;
  height: 100%;
}

.top-mv--pic {
  position: absolute;
  top: -10px;
  right: 0;
}

.top-intro--ttl {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.7;
  padding: 25px 0;
  background: #f2f2f2;
  text-align: center;
}

.top-intro--txt {
  font-size: 20px;
  line-height: 1.7;
  text-align: center;
  margin-bottom: 100px;
}

.top-intro--txt strong {
  font-weight: 700;
  color: #ff8a16;
}

.top-intro--appeal {
  background-color: #f4b800;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.5;
  padding: 40px 0;
}

.top-intro--appeal_sub {
  font-size: 32px;
  display: block;
  margin-bottom: 5px;
}

.top-intro--appeal_point {
  background-color: #fff;
  padding-left: 5px;
}

.top-intro .m-btn {
  width: 436px;
  margin: 60px auto 0;
}

.top-intro_list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
  margin-bottom: 90px;
}

.top-intro_list--item {
  width: 33.3%;
  text-align: center;
  padding: 10px;
}

.top-intro_list--txt {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;
  color: #ff8a16;
  margin-top: 10px;
}

.top-target_req {
  margin-top: 50px;
  padding-left: 55px;
  padding-right: 55px;
}

.top-target_req:after {
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  font-size: 0;
  content: '.';
}

.top-target_req--pic {
  float: left;
}

.top-target_req--lead {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  float: right;
  width: 560px;
}

.top-target_req--lead strong {
  border-bottom: 5px solid #f4b800;
}

.top-target_req--lead_age {
  font-size: 30px;
  font-weight: 700;
  color: #ff8a16;
}

.top-target_req_list {
  width: 560px;
  float: right;
  margin-top: 40px;
}

.top-target_req_list--item {
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  color: #ff8a16;
  padding: 20px 90px;
  border: 3px solid #f4b800;
  border-radius: 50px;
  margin-top: 30px;
  position: relative;
}

.top-target_req_list--item:before {
  font-size: 50px;
  position: absolute;
  top: 15px;
  left: 20px;
  color: #000;
}

.top-target_req_list--item:first-child {
  margin-top: 0;
}

.top-target_req_list--item:first-child:before {
  content: '①';
}

.top-target_req_list--item:last-child:before {
  content: '②';
}

.top-target_ex {
  background: #f2f2f2;
  padding-top: 60px;
  padding-bottom: 70px;
}

.top-target_ex_list {
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 65px;
  display: flex;
  flex-wrap: wrap;
}

.top-target_ex_list--item {
  width: 50%;
  height: 70px;
  font-size: 24px;
  font-weight: 700;
  padding-left: 90px;
  margin-bottom: 30px;
  position: relative;
  display: flex;
  align-items: center;
}

.top-target_ex_list--item:before {
  content: '';
  width: 70px;
  height: 70px;
  background: url("../../images/bg-check-icon.png") no-repeat center center;
  background-size: cover;
  position: absolute;
  left: 0;
}

.top-merit .m-btn {
  width: 300px;
  margin: 50px auto 0;
}

.top-merit_features {
  margin-top: 50px;
}

.top-merit_features--item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.top-merit_features--item:nth-child(odd) .top-merit_features--ttl {
  text-align: right;
}

.top-merit_features--description {
  width: 440px;
}

.top-merit_features--ttl {
  color: #ff8a16;
  font-size: 40px;
  line-height: 1.7;
  padding-bottom: 15px;
  border-bottom: 4px dotted #f4b800;
}

.top-merit_features--txt {
  line-height: 1.8;
  margin-top: 25px;
}

.top-voice_list {
  width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
}

.top-voice_list--item {
  flex-basis: 320px;
  max-width: 320px;
  padding: 20px;
  margin-bottom: 20px;
  border: 2px solid #ff8a16;
  border-radius: 10px;
}

.top-voice_list--heading {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.3;
  color: #fff;
  text-align: center;
  background: #f4b800;
  border-radius: 40px;
  padding: 10px;
}

.top-voice_list--txt {
  font-size: 14px;
  line-height: 1.7;
  margin-bottom: 0;
}

.top-schedule--ttl_sub {
  font-size: 40px;
  color: #ff8a16;
  background-color: #fff;
  border-radius: 30px;
  padding: 0 35px;
  margin-right: 20px;
}

.top-schedule_list {
  margin: 30px auto 50px;
}

.top-schedule_list--item {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.7;
}

.top-schedule_list--item_heading {
  color: #ff8a16;
}

.top-schedule_list--item_heading:after {
  content: '：';
  color: #333;
}

.top-schedule_list--item_note {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  display: block;
}

.top-schedule_list--item_link {
  font-size: 20px;
  font-weight: normal;
}

.top-hr_link {
  position: fixed;
  top: 187px;
  right: 0;
  writing-mode: vertical-rl;
  background-color: #5E5E5E;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border-radius: 15px 0 0 15px;
  border: 1px solid #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  padding: 24px 16px 51px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2217%22%20height%3D%2217%22%3E%20%3Cpath%20data-name%3D%22%E5%89%8D%E9%9D%A2%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%81%A7%E5%9E%8B%E6%8A%9C%E3%81%8D%206%22%20d%3D%22M8.5%2016.5a8%208%200%201%201%208-8%208.009%208.009%200%200%201-8%208ZM7.719%203.211%205.834%205.1l3.403%203.4-3.4%203.4%201.885%201.885L13%208.51l-.01-.01.01-.01-5.28-5.28Z%22%20fill%3D%22%23fff%22%20stroke%3D%22rgba(0%2C0%2C0%2C0)%22%20stroke-miterlimit%3D%2210%22%2F%3E%3C%2Fsvg%3E");
  background-position: center bottom 24px;
  background-repeat: no-repeat;
  transition: 0.2s;
  z-index: 4;
}

.top-hr_link:hover {
  opacity: 0.8;
}

.top-schedule_covid {
  max-width: 748px;
  margin: 0 auto;
  padding: 24px;
  background-color: #F2F2F2;
}

@media (max-width: 425px) {
  .top-schedule_covid {
    margin-left: 15px;
    margin-right: 15px;
    padding: 16px;
  }
}

.top-schedule_covid--lead {
  font-weight: bold;
  line-height: 1.5;
  font-size: 16px;
  text-align: center;
}

@media (max-width: 425px) {
  .top-schedule_covid--lead {
    font-size: 14px;
  }
}

.top-schedule_covid--desc {
  line-height: 1.5;
  font-size: 16px;
  margin-top: 16px;
}

@media (max-width: 425px) {
  .top-schedule_covid--desc {
    font-size: 14px;
    margin-top: 8px;
  }
}

@media screen and (max-width: 600px) {
  .top-mv {
    height: auto;
    padding-top: 20px;
    padding-bottom: 40px;
    margin-bottom: 0;
  }
  .top-mv .inner {
    padding-left: 0;
    padding-right: 0;
  }
  .top-mv--ttl {
    font-size: 30px;
    line-height: 1.3;
    text-align: center;
  }
  .top-mv--ttl_sub {
    display: none;
  }
  .top-mv--ttl_sub_sp {
    display: block;
  }
  .top-mv--pic {
    display: none;
  }
  .top-mv .m-btn {
    width: calc(100% - 40px);
    margin: 15px 20px 0;
  }
  .top-intro {
    padding-bottom: 80px;
  }
  .top-intro--ttl {
    font-size: 22px;
    line-height: 1.4;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .top-intro--txt {
    font-size: 16px;
    margin-bottom: 60px;
  }
  .top-intro--appeal {
    font-size: 24px;
    padding: 20px 0;
  }
  .top-intro--appeal_sub {
    font-size: 24px;
  }
  .top-intro .m-btn {
    width: 300px;
    margin-top: 30px;
  }
  .top-intro_list {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .top-intro_list--item {
    width: 50%;
  }
  .top-intro_list--txt {
    font-size: 16px;
  }
  .top-merit {
    padding-bottom: 80px;
  }
  .top-merit_features {
    margin-top: 30px;
  }
  .top-merit_features--item {
    margin-top: 30px;
  }
  .top-merit_features--item:first-child {
    margin-top: 0;
  }
  .top-merit_features--item:nth-child(odd) .top-merit_features--ttl {
    text-align: center;
  }
  .top-merit_features--description {
    width: 100%;
  }
  .top-merit_features--ttl {
    font-size: 24px;
    text-align: center;
    padding-bottom: 5px;
  }
  .top-merit_features--txt {
    margin-top: 10px;
  }
  .top-merit_features--pic {
    order: -1;
    width: 70vw;
  }
  .top-voice_list {
    width: 100%;
  }
  .top-voice_list--item {
    margin: 0 5px;
  }
  .top-schedule {
    padding-bottom: 70px;
  }
  .top-schedule--ttl_sub {
    font-size: 22px;
    padding: 0 10px;
    margin-right: 10px;
  }
  .top-schedule_list--item {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .top-schedule_list--item:last-child {
    margin-bottom: 0;
  }
  .top-schedule_list--item_heading {
    width: 100%;
    text-align: center;
  }
  .top-schedule_list--item_heading:after {
    content: '';
  }
  .top-schedule_list--item_note {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }
  .top-schedule_list--item_link {
    font-size: 16px;
    display: block;
  }
  .top-target_req {
    margin-top: 30px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 15px;
    position: relative;
  }
  .top-target_req--lead {
    float: none;
    width: auto;
    font-size: 16px;
  }
  .top-target_req--lead_age {
    font-size: 24px;
  }
  .top-target_req--pic {
    width: 30vw;
    height: auto;
    position: absolute;
    left: 10px;
    bottom: 0;
  }
  .top-target_req_list {
    width: 210px;
    margin-top: 30px;
  }
  .top-target_req_list--item {
    font-size: 16px;
    padding: 10px 10px 10px 38px;
    border-radius: 20px;
    margin-top: 15px;
  }
  .top-target_req_list--item:before {
    font-size: 24px;
    top: 50%;
    left: 7px;
    margin-top: -12px;
  }
  .top-target_ex {
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .top-target_ex_list {
    margin-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .top-target_ex_list--item {
    width: 100%;
    height: 27px;
    font-size: 16px;
    padding-left: 40px;
    margin-bottom: 10px;
  }
  .top-target_ex_list--item:before {
    width: 28px;
    height: 27px;
  }
  .top-hr_link {
    display: none;
  }
}
